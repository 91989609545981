angular.module("vdsr").controller("pwrecController", [
   "$scope",
   "$http",
   "$location",
   "$window",
   function($scope, $http, $location, $window) {
      $scope.successMsg = false;
      $scope.errMsg;
      $scope.message = undefined;
      $scope.typePassword = true;

      $scope.logoObj = $window.stw.globals.companyImages.logos.loginlogo;
      $scope.getLogoSizeConstraints = function() {
         var imageTypeObj = $scope.logoObj;
         var props = ["width", "height", "maxWidth", "maxHeight"];
         var style = {};
         angular.forEach(props, function(v) {
            if (imageTypeObj[v]) {
               var snake_case = v.replace(/([A-Z])/g, "-$1").toLowerCase();
               style[snake_case] = imageTypeObj[v];// + "px";
            }
         });
         return style;
      };

      $scope.relocate = function() {
         $window.location.href = "/login?pwdReset";
      };
      $scope.pwrecSubmit = function() {
         $scope.processing = true;
         $http({
            method: "post",
            url: "/pwrecovery",
            data: {
               newpw: $scope.newpw,
               location: $location.absUrl()
            }
         })
         .success(function(r) {
            $scope.message = r;
            $scope.relocate();
         })
         .error(function(e) {
            $scope.errMsg = e.error.message; //"There was an error processing your request!";
            $scope.processing = false;
            $scope.successMsg = false;
         });
      };

      $scope.togglePasswordViewable  = () => {
         $scope.typePassword = !$scope.typePassword;
      }
   }
]);
